.analysis {
  width: 100%;
  max-width: 1300px;
  h2, h4 {
    margin: 0;
  }
  .loader {
    height: calc(100vh - 56px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .analysis-header {
    width: 100%;
    padding: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .analysis-table {
    width: 100%;
    overflow-x: scroll;
    margin: 0 0 25px 0;
    table {
      width: 100%;
      min-width: 900px;
      table-layout: fixed;
      td {
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .group-name {
        width: 20%;
        word-wrap: break-word;
        white-space: normal;
      }
      .study {
        width: 20%;
        white-space: nowrap;
      }
      .cases {
        width: 10%;
        white-space: nowrap;
      }
      .filters { 
        width: 30%;
        word-wrap: break-word;
        white-space: normal;
        text-align: justify;
      }
      .remove-button {
        width: 20%;
        div {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
}

.analysis-graphs {
  width: 100%;
  height: 800px;
}

.analysis-modal {
  .modal-dialog {
    max-width: 1000px;
    .modal-title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 20px 0 0;
    }
    .modal-body {
      form {
        margin: 0 0 20px 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .group-study, .group-name {
          width: 47.5%;
        }
      }
      .divider {
        width: 100%;
        border-top: 1px solid #dee2e6;
        margin: 30px 0 20px 0;
      }
      .analysis-group-metadata {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .accordion-button {
          font-weight: bold;
        }
        .analysis-group-metadata-overview {
          width: 30%;
          padding-right: 50px;
          .accordion {
            .accordion-item {
              border-left: 10px solid black;
              .accordion-collapse, .collapse, .show {
                .accordion-body  {
                  border-top: 1px dashed #dee2e6;
                }
              }
              .accordion-body {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                button {
                  background-color: white;
                  border: none;
                  text-align: left;
                  &:hover {
                    font-weight: bold;
                  }
                }
              }
            }
            .patient-information-and-surgical-time-and-personnel, .hand-dominance {
              border-left-color: rgb(57, 72, 92);
            }
            .diagnosis {
              border-left-color: rgb(213, 88, 70);
            }
            .operations, .operations-labrum-and-instability {
              border-left-color: rgb(85, 171, 103);
            }
            .grafts {
              border-left-color: rgb(86, 126, 180);
            }
            .implants {
              border-left-color: rgb(133, 72, 167);
            }
            .surgicalDressing {
              border-left-color: rgb(88, 186, 157);
            }
            .postOperativeManagement {
              border-left-color: rgb(88, 186, 157);
            }
          }
        }
        .analysis-group-metadata-details {
          width: 70%;
          .analysis-group-metadata-details-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 8px 0;
            h4 {
              margin: 0;
            }
          }
          .accordion {
            margin-bottom: 10px;
            .accordion-custom-header {
              width: 100%;
              display: flex;
              flex-direction: row;
              .accordion-header {
                width: 100%;
              }
              .remove-catalog-entry-button {
                width: 52px;
                height: 52px;
                display: flex;
                justify-content: center;
                align-items: center;
                fill-opacity: 0.3;
                &:hover {
                  fill-opacity: 1;
                  color: red;
                }
              }  
            }
          }
        }
      }
      .row {
        margin-bottom: 10px;
      }
    }
  }
}