.login {
  height: calc(100vh - 112px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  Form {
    width: 600px;
    .card {
      .card-header {
        background-color: white;
      }
    }
    p {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 15pt;
    }
    .logo {
      text-align: center;
      color: rgba(76, 175, 255, 1);
      font-weight: bold;
      font-size: 30pt;
    }
    .form-group {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .btn-primary {
      width: 100%;
      border-color: rgba(76, 175, 255, 1);;
      background-color: rgba(76, 175, 255, 1);
      &:hover{
        background-color: rgba(76, 175, 255, 0.5);
        border-color: rgba(76, 175, 255, 0.5);
      }
    }
  }
}