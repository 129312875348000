.circle-progress-bar {
  circle {
    transition: all 0.7s ease-in-out;
    cursor: pointer;
  }

  button {
    padding: 1em;
    font-size: 1rem;
    border: none;
    background-color: #dddddd;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  button:hover {
    background-color: #4d4d4d;
    color: white;
  }
}