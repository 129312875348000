.complication {
  width: 100%;
  max-width: 1300px;
  .loader {
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h2 {
    margin: 0 0 10px 0;
  }
  .complication-header {
    width: 100%;
    padding: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .complication-metadata {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .accordion-button {
      font-weight: bold;
    }
    .complication-metadata-overview {
      width: 30%;
      padding-right: 50px;
      .complication-metadata-catalog-header {
        height: 40px;
        margin: 0 0 10px 0;
        display: flex;
        align-items: center;
        h4 {
          margin: 0;
        }
      }
      .accordion {
        .accordion-item {
          border-left: 10px solid rgb(57, 72, 92);
          .accordion-collapse, .collapse, .show {
            .accordion-body  {
              border-top: 1px dashed #dee2e6;
            }
          }
          .accordion-body {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            button {
              background-color: white;
              border: none;
              text-align: left;
              &:hover {
                font-weight: bold;
              }
            }
          }
        }
        .complications {
          border-left-color: rgb(57, 72, 92);
        }
      }
    }
    .complication-metadata-details {
      width: 70%;
      .complication-metadata-details-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        margin: 0 0 10px 0;
        h4 {
          margin: 0;
        }
        .action-buttons {
          display: flex;
          flex: row;
          justify-content: flex-end;
          button {
            margin: 0 0 0 10px;
          }    
        }
      }
      .accordion {
        margin-bottom: 10px;
        .accordion-custom-header {
          width: 100%;
          display: flex;
          flex-direction: row;
          .accordion-header {
            width: 100%;
          }
          .remove-catalog-entry-button {
            width: 52px;
            height: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
            fill-opacity: 0.3;
            &:hover {
              fill-opacity: 1;
              color: red;
            }
          }  
        }
      }
    }
  }
  .row {
    margin-bottom: 10px;
  }
}

@media (max-width: 900px) {
  .complication {
    .complication-metadata {
      flex-direction: column;
      .complication-metadata-overview {
        width: 100%;
        padding: 0;
        margin: 0 0 50px 0;
      }
      
      .complication-metadata-details {
        width: 100%;
      }
    }
  }

}

@media (max-width: 750px) {
  .complication-metadata {
    .complication-details-header {
      width: 100%;
      padding: 20px 0 10px 0;
      margin: 0 0 30px 0;
      .complication-title {
        .buttons {
          display: flex;
          flex-direction: column;
          button:first-of-type {
            margin: 0 0 5px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .complication-metadata {
    .complication-details-header {
      width: 100%;
      padding: 20px 0 10px 0;
      margin: 0 0 30px 0;
      .complication-title {
        .sugery-info {
          .patient-and-date {
            flex-direction: column;
          }
        }
      }
    }
  }
}