.grafts-bone-graft-autografts, 
.grafts-bone-graft-allografts, 
.grafts-bone-graft-synthetic-bone-void-fillers, 
.grafts-cartilage-graft-chondral-grafts, 
.grafts-cartilage-graft-osteochondral-grafts, 
.grafts-soft-tissue-tendon-grafts, 
.grafts-soft-tissue-augmentation-grafts,
.grafts-soft-tissue-adipose-tissue-graft,
.grafts-soft-tissue-meniscal-allograft-fascia-lata {
  .accordion {
    .accordion-item{
      border: 2px solid rgb(86, 126, 180);
      border-left: 10px solid rgb(86, 126, 180);
      .catalog-entry-header {
        width: 100%;
        .accordion-header {
          margin: 0;
        }
      }
      .catalog-entry-preview {
        padding: 0 20px;
        font-size: 0.8em;
        font-weight: lighter;
        margin: 0 0 10px 0;
        span {
          white-space:nowrap;
        }
        .catalog-entry-preview-key {
          font-weight: bold;
        }
      }
      .accordion-collapse, .collapse, .show {
        .accordion-body  {
          border-top: 1px solid rgb(86, 126, 180);
        }
      }
    }
  }
}