.header {
  .navbar {
    width: 100%;
    margin-left: 10x;
    background-color: white;
    .navbar-brand {
      .logo {
        color: rgba(76, 175, 255, 1);
        font-weight: bold;
        font-size: 15pt;
      }
    }
    .navbar-nav {
      .nav-link {
        color: white;
        font-size: 10pt;
        .badge.bg-danger {
          vertical-align: middle;
          margin-top: -3px;
        }
      }
      .btn-primary {
        background-color: rgba(76, 175, 255, 1);
        border-color: rgba(76, 175, 255, 1);
        &:hover{
          background-color: rgba(76, 175, 255, 0.5);
          border-color: rgba(76, 175, 255, 0.5);
        }
      }
    }
    .navbar-toggler {
      border-color: white;
      .navbar-toggler-icon {
        border-color: white;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      }
    }
    .dropdown {
      color: white;
      font-size: 10pt;
    }
    .search {
      width: 250px;
      .react-search-autocomplete {
        z-index: 99;
      }
      .custom-patient-id {
        display: block;
        text-align: left;
        font-weight: bold;
      }
      .patient-email {
        display: block;
        overflow: hidden;
        text-overflow:ellipsis;
        text-align: left;
      }
      .patient-study {
        display: block;
        text-align: left;
      }
    }
  }
}