.App {
  width: 100%;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
  }
  .otos-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .collapsing {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s !important;
    transition: height 0.2s ease !important;
  }

  *:focus { 
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    color: black;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
    border-color: transparent;
    background-color: white; 
  }
  .breadcrumb {
    .breadcrumb-item {
      a {
        color: black;
        text-decoration: none;
        &:hover {
          color: rgba(33, 37, 41, 0.75);
        }
      }
    }
  }
    /* Navigation buttons (Previous and Next) */
  .react-datepicker__navigation {
    top: 15px; /* Align the navigation buttons closer to the middle */
    line-height: 1.2rem;
    width: 20px; /* Adjust button size for proper spacing */
    height: 20px; /* Ensure consistent size */
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__navigation--previous {
    left: 10px; /* Add spacing on the left */
  }

  .react-datepicker__navigation--next {
    right: -10px; /* Add spacing on the right */
  }

  /* Arrow customization */
  .react-datepicker__navigation-icon::before {
    content: ''; /* Remove the default arrow */
    display: inline-block;
    border: solid #0d6efd; /* Bootstrap primary color */
    border-width: 0 2px 2px 0;
    padding: 5px;
    transform: rotate(135deg); /* Default arrow direction */
  }

  .react-datepicker__navigation--next .react-datepicker__navigation-icon::before {
    transform: rotate(-45deg); /* Flip the arrow for "next" */
  }

  /* Add hover effects for buttons */
  .react-datepicker__navigation:hover .react-datepicker__navigation-icon::before {
    border-color: #0056b3; /* Darker primary color on hover */
  }

  /* Dropdown styles for month/year selectors */
  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    margin-top: 5px; /* Add spacing from the header */
    padding: 5px;
    background: white;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  /* Dropdown arrows alignment */
  .react-datepicker__month-container {
    padding: 0;
  }
  
  // .btn-primary {
  //   background-color: rgba(76, 175, 255, 1);
  //   border-color: rgba(76, 175, 255, 1);
  //   &:hover{
  //     background-color: rgba(76, 175, 255, 0.5);
  //     border-color: rgba(76, 175, 255, 0.5);
  //   }
  // }
}

@media (max-width: 900px) {
  .container-xxl {
    .patients, .dashboard {
      font-size: 0.8rem; 
    }
  }
}

// @media (max-width: 750px) {
//   .container-xxl {
//     .patients, .dashboard {
//       font-size: 0.7rem; 
//     }
//   }
// }
// @media (max-width: 690px) {
//   .container-xxl {
//     .patients, .dashboard {
//       font-size: 0.6rem; 
//     }
//   }
// }

.App ::-webkit-scrollbar { 
  display: none; 
}