.surgery-details {
  width: 100%;
  max-width: 1300px;
  .loader {
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h2 {
    margin: 0 0 10px 0;
  }
  .surgery-details-header {
    width: 100%;
    padding: 20px 0 10px 0;
    margin: 0 0 30px 0;
    .surgery-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin: 0 0 10px 0; 
      .buttons {
        button {
          // width: 100px;
          height: 38px;
        }
        button:first-of-type {
          margin: 0 10px 0 0;
        }
      }
    }
    .patient-and-date {
      width: 100%;
      display: flex;
      p {
        margin: 0 10px 0 0;
      }
    }
  }

  .surgery-metadata {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .accordion-button {
      font-weight: bold;
    }
    .surgery-metadata-overview {
      width: 30%;
      padding-right: 50px;
      .accordion {
        .accordion-item {
          border-left: 10px solid black;
          .accordion-collapse, .collapse, .show {
            .accordion-body  {
              border-top: 1px dashed #dee2e6;
            }
          }
          .accordion-body {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            button {
              background-color: white;
              border: none;
              text-align: left;
              &:hover {
                font-weight: bold;
              }
            }
          }
        }
        .patient-information-and-surgical-time-and-personnel, .hand-dominance {
          border-left-color: rgb(57, 72, 92);
        }
        .diagnosis {
          border-left-color: rgb(213, 88, 70);
        }
        .operations, .operations-labrum-and-instability {
          border-left-color: rgb(85, 171, 103);
        }
        .grafts {
          border-left-color: rgb(86, 126, 180);
        }
        .implants {
          border-left-color: rgb(133, 72, 167);
        }
        .surgicalDressing {
          border-left-color: rgb(88, 186, 157);
        }
        .postOperativeManagement {
          border-left-color: rgb(88, 186, 157);
        }
      }
    }
    .surgery-metadata-details {
      width: 70%;
      .surgery-metadata-details-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 8px 0;
        h4 {
          margin: 0;
        }
      }
      .accordion {
        margin-bottom: 10px;
        .accordion-custom-header {
          width: 100%;
          display: flex;
          flex-direction: row;
          .accordion-header {
            width: 100%;
          }
          .remove-catalog-entry-button {
            width: 52px;
            height: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
            fill-opacity: 0.3;
            &:hover {
              fill-opacity: 1;
              color: red;
            }
          }  
        }
      }
    }
  }
  .row {
    margin-bottom: 10px;
  }
}

@media (max-width: 900px) {
  .surgery-details {
    .surgery-metadata {
      flex-direction: column;
      .surgery-metadata-overview {
        width: 100%;
        padding: 0;
        margin: 0 0 50px 0;
      }
      
      .surgery-metadata-details {
        width: 100%;
      }
    }
  }
}

@media (max-width: 750px) {
  .surgery-details {
    .surgery-details-header {
      width: 100%;
      padding: 20px 0 10px 0;
      margin: 0 0 30px 0;
      .surgery-title {
        .buttons {
          display: flex;
          flex-direction: column;
          button:first-of-type {
            margin: 0 0 5px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .surgery-details {
    .surgery-details-header {
      width: 100%;
      padding: 20px 0 10px 0;
      margin: 0 0 30px 0;
      .surgery-title {
        .sugery-info {
          .patient-and-date {
            flex-direction: column;
          }
        }
      }
    }
  }
}