.enroll-patient {
  width: 100%;
  max-width: 800px;
  .loader {
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .patient-header {
    width: 100%;
    padding: 20px 0 10px 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
  .form-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .form-group {
      margin: 20px 0;
      .datepicker-container {
        position: relative;
        display: flex;
        align-items: center;
      }

      /* Style the input field */
      .datepicker-container .form-control {
        padding-right: 2rem; /* Add space for the icon */
      }

      /* Style the calendar icon */
      .datepicker-container .calendar-icon {
        position: absolute;
        right: 10px; /* Align it with the input's right edge */
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 2; /* Ensure it stays above other elements */
      }
      /* Restrict the popper width to the actual datepicker */
      .react-datepicker-popper {
        width: auto !important; /* Remove unnecessary width */
        z-index: 1050; /* Ensure it's above other elements */
      }

      /* Ensure DatePicker dropdown is not larger than its content */
      .react-datepicker {
        width: auto;
        max-width: none;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      }

      /* Proper alignment with the input */
      .react-datepicker__triangle {
        display: none; /* Hide the triangle if not needed */
      }
      .datepicker-wrapper-div > div {
        display: inline;
      }
    }
    .general-information {
      width: 50%;
      padding: 20px 20px 20px 0;
    }
    .localization {
      width: 50%;
      padding: 20px 0 20px 20px;
    }
  }
  button {
    width: 140px; 
    margin-right: 20px;
  }
}