.clinic-visit {
  width: 100%;
  max-width: 800px;
  margin: 0 0 50px 0;
  .loader {
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clinic-visit-header {
    width: 100%;
    padding: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .form-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    .form-group {
      margin: 20px 0;
    }
  }
  button {
    width: 120px; 
    margin-right: 20px;
  }
}