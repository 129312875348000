.operations-rotator-cuff-complete-tear,
.operations-rotator-cuff-partial-tear,
.operations-superior-capsule-reconstruction,
.operations-subscap,
.operations-sub-acromial-decompression,
.operations-tendon-transfer,
.operations-biceps,
.operations-coracoplasty,
.operations-distal-clavicle-excision,
.operations-ac-joint-stabilization,
.operations-os-acromiale,
.operations-osteotomy,
.operations-cartilage-treatment,
.operations-osteochondral-treatment,
.operations-other-intra-articular,
.operations-fracture-management,
.operations-miscellaneous,
.operations-case-details,
.operations-tendon-procedures,
.operations-collateral-ligaments,
.operations-osteophyte-debridement-excision,
.operations-removal-loose-body,
.operations-capsule-synovium,
.operations-nerve,
.operations-orif,
.operations-radial-head-excision,
.operations-radial-head-implant,
.operations-total-elbow-arthroplasty {
  .accordion {
    .accordion-item{
      border: 2px solid rgb(85, 171, 103);
      border-left: 10px solid rgb(85, 171, 103);
      .catalog-entry-header {
        width: 100%;
        .accordion-header {
          margin: 0;
        }
      }
      .catalog-entry-preview {
        padding: 0 20px;
        font-size: 0.8em;
        font-weight: lighter;
        margin: 0 0 10px 0;
        span {
          white-space:nowrap;
        }
        .catalog-entry-preview-key {
          font-weight: bold;
        }
      }
      .accordion-collapse, .collapse, .show {
        .accordion-body  {
          border-top: 1px solid rgb(85, 171, 103);
        }
      }
    }
  }
}