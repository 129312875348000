.patient-dashboard  {
  width: 100%;
  max-width: 1300px;
  h2, h4 {
    margin: 0;
  }
  .loader {
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .patient-dashboard-header {
    width: 100%;
    padding: 20px 0 10px 0;
    margin: 0 0 30px;
    display: flex;
    flex-direction: column;
    .patient-id {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin: 0 0 10px;
      button {
        width: 132px;
      }
    }
  }
  .patient-dashboard-subheader {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .patient-info {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      p {
        margin: 0 20px 0 0;
        .show-hide-anchor {
          color: blue;
          text-decoration: underline;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .patient-dashboard-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .left-column {
      width: 35%;
      display: flex;
      flex-direction: column;
      .recent-activity {
        width: 100%;
        margin: 0 0 30px 0;
        .recent-activity-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 30px 0;
        }
        .timeline {
          width: 100%;
          display: flex;
          justify-content: center;
          .vertical-timeline-element {
            margin: 0 0 30px;
          }
          .vertical-timeline-element-content-arrow {
            height: 10px;
            width: 10px;
            top: 15.5px;
            left: -20px;
            border-right: 15px solid transparent;
          }
          .vertical-timeline-element-icon {
            width: 15px;
            height: 15px;
            top: 17.5px;
            left: 12.5px;
            box-shadow: none;
          }
          .vertical-timeline-element-content {
            height: 50px;
            width: 250px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            box-shadow: none;
            cursor: pointer;
            p {
              font-size: 0.85rem;
              padding: 0;
              margin: 0;
            }
            p:nth-of-type(2n) {
              font-weight: bold;
            }
            .timeline-event-study {
              width: 75px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .timeline-event-description {
              min-width: calc(100% - 75px);
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              // padding: 0 0 0 20px;
              background-color: transparent;
            }
          }
          .survey, .pending-survey {
            .vertical-timeline-element-content-arrow {
              border-right: 15px solid rgb(102, 102, 102);
            }
            .vertical-timeline-element-icon {
              background-color: rgb(102, 102, 102);
            }
            .vertical-timeline-element-content {
              border: 1px solid rgb(102, 102, 102);
              .timeline-event-study {
                background-color: rgb(102, 102, 102);
                color: white;
              }
            }
          }
          .clinic-visit {
            .vertical-timeline-element-content-arrow {
              border-right: 15px solid #9933CC;
            }
            .vertical-timeline-element-icon {
              background-color: #9933CC;
            }
            .vertical-timeline-element-content {
              border: 1px solid #9933CC;
              .timeline-event-study {
                background-color: #9933CC;
                color: white;
              }
              .timeline-event-description {
                color: #9933CC;
              }
            }
          }
          .complication {
            .vertical-timeline-element-content-arrow {
              border-right: 15px solid #BB3F3F;
            }
            .vertical-timeline-element-icon {
              background-color: #BB3F3F;
            }
            .vertical-timeline-element-content {
              border: 1px solid #BB3F3F;
              .timeline-event-study {
                background-color: #BB3F3F;
                color: white;
              }
              .timeline-event-description {
                color: #BB3F3F;
              }
            }
          }
          .surgery {
            .vertical-timeline-element-content-arrow {
              border-right: 15px solid rgb(101, 201, 122);
            }
            .vertical-timeline-element-icon {
              background-color: rgb(101, 201, 122);
            }
            .vertical-timeline-element-content {
              border: 1px solid rgb(101, 201, 122);
              .timeline-event-study {
                background-color: rgb(101, 201, 122);
                color: white;
              }
              .timeline-event-description {
                color: rgb(101, 201, 122);
              }
            }
          }
        }
      }
      .alerts {
        // background-color: lightblue;
        width: 100%;
      }
    } 
    .right-column {
      width: 65%;
      padding: 0 0 0 70px;
      display: flex;
      flex-direction: column;
      .pain-scale {
        width: 100%;
        margin: 0 0 50px 0;
        .pain-scale-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 30px 0;
        }
        .graphs {
          width: 100%;
          height: 800px;
        }
      }
      
    }
  }
}

@media (max-width: 900px) {
  .patient-dashboard {
    .patient-dashboard-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .patient-dashboard-subheader {
        .patient-info{
          flex-direction: column;
        }
      }
      .left-column {
        width: 100%;
      }
      .right-column {
        width: 100%;
        padding: 0;
      }
    }
  }
}

@media (max-width: 550px) {
  .patient-dashboard {
    .patient-dashboard-subheader {
      .patient-info {
        flex-direction: column;
      }
    }
  }
}