.diagnosis-rotator-cuff,
.diagnosis-subacromial-impingement,
.diagnosis-labral-injury-and-instability,
.diagnosis-biceps,
.diagnosis-ac-joint,
.diagnosis-os-acromiale,
.diagnosis-glenohumeral-arthritis,
.diagnosis-articluar-cartilage-osteochondral-lesion,
.diagnosis-other-intra-articular,
.diagnosis-fracture,
.diagnosis-miscellaneous,
.diagnosis-arthritis,
.diagnosis-rotator-cuff-arthropathy,
.diagnosis-failed-rotator-cuff-repair, 
.diagnosis-failed-shoulder-arthroplasty,
.diagnosis-clavicle-fracture,
.diagnosis-humeral-fracture,
.diagnosis-scapula-fracture,
.diagnosis-chronic-rotator-cuff-tear,
.diagnosis-tendon,
.diagnosis-ligament-instability,
.diagnosis-elbow-osteochondral-lesion,
.diagnosis-elbow-osteoarthritis,
.diagnosis-elbow-other-inflammatory-arthritis,
.diagnosis-elbow-loose-body,
.diagnosis-elbow-capsule-synovium,
.diagnosis-elbow-nerve,
.diagnosis-elbow-fracture,
.diagnosis-olecranon-spur,
.diagnosis-other {
  .accordion {
    .accordion-item{
      border: 2px solid rgb(213, 88, 70);
      border-left: 10px solid rgb(213, 88, 70);
      .catalog-entry-header {
        width: 100%;
        .accordion-header {
          margin: 0;
        }
      }
      .catalog-entry-preview {
        padding: 0 20px;
        font-size: 0.8em;
        font-weight: lighter;
        margin: 0 0 10px 0;
        span {
          white-space:nowrap;
        }
        .catalog-entry-preview-key {
          font-weight: bold;
        }
}
      .accordion-collapse, .collapse, .show {
        .accordion-body  {
          border-top: 1px solid rgb(213, 88, 70);
        }
      }
    }
  }
}
