.ases-elbow-objective-measures {
  width: 100%;
  .form-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .form-label {
      width: 60%;
      margin: 0 10px 0 0;
    }
    .form-control, .form-select {
      width: 40%;
    }
  }
}