.self-assessment-header {
  background-color: red;
  .navbar {
    width: 100%;
    // background-color: rgba(33,37,41);
    display: flex;
    justify-content: center;
    align-items: center;
    .navbar-brand {
      color: rgba(76, 175, 255, 1);
      font-weight: bold;
      font-size: 15pt;
    }
  }
}