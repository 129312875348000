.patient-compliance {
  width: 100%;
  max-width: 1300px;
  h2, h4 {
    margin: 0;
  }
  .loader {
    height: calc(100vh - 56px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .patient-compliance-header {
    width: 100%;
    padding: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .patient-compliance-table {
    width: 100%;
    overflow-x: scroll;
    table {
      width: 100%;
      min-width: 900px;
      table-layout: fixed;
      td {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        vertical-align: middle;
      }
      .custom-id {
        width: 15%;
      }
      .study {
        width: 10%;
      }
      .event-date {
        width: 15%;
      }
      .milestone { 
        width: 12.5%;
      }
      .date-of-submission, .survey-deadline {
        width: 15%
      }
      .actions {
        width: 12.5%;
      }
      .action-buttons {
        width: 5%;
      }
      td.action-buttons {
        text-overflow: clip;
        div {
          width: 100%;
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
}