.implants-ac-joint-stabilization, 
.implants-biceps, 
.implants-capsular-repair-hagl-alpsa-remplissage-asa, 
.implants-chondral, 
.implants-fracture-management, 
.implants-glenoid-bone-graft-procedure, 
.implants-humeral-bone-graft-procedure, 
.implants-labral-repair, 
.implants-os-acromiale, 
.implants-osteotomy, 
.implants-ac-joint-stabilization, 
.implants-pec-major, 
.implants-rotator-cuff-implants-lateral-row, 
.implants-rotator-cuff-implants-medial-row, 
.implants-slap, 
.implants-subscap, 
.implants-superior-capsule-reconstruction-glenoid, 
.implants-superior-capsule-reconstruction-humeral-side-lateral-row, 
.implants-superior-capsule-reconstruction-humeral-side-medial-row,
.implants-tendon-procedures,
.implants-collateral-ligaments,
.implants-cartilage-treatment,
.implants-osteochondral-treatment,
.implants-orif,
.implants-radial-head-implant,
.implants-total-elbow-arthroplasty {
  .accordion {
    .accordion-item{
      border: 2px solid rgb(133, 72, 167);
      border-left: 10px solid rgb(133, 72, 167);
      .catalog-entry-header {
        width: 100%;
        .accordion-header {
          margin: 0;
        }
      }
      .catalog-entry-preview {
        padding: 0 20px;
        font-size: 0.8em;
        font-weight: lighter;
        margin: 0 0 10px 0;
        span {
          white-space:nowrap;
        }
        .catalog-entry-preview-key {
          font-weight: bold;
        }
}
      .accordion-collapse, .collapse, .show {
        .accordion-body  {
          border-top: 1px solid rgb(133, 72, 167);
        }
      }
    }
  }
}