.terms-and-conditions{
  width: 100%;
  .card-title {
    text-align: center;
  }
  .card-body {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .content {
      max-width: 650px;      
      text-align: justify;
    }
  }
  .card-footer {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}