.operations-approach-supscap-repair-and-application-braces,
.operations-hemiarthroplasty,
.operations-humeral-head-resurfacing,
.operations-biologic-total-shoulder,
.operations-total-shoulder-replacement,
.operations-inverse-reverse-total-shoulder-replacement,
.operations-superior-capsule-reconstruction,
.operations-clavicle-fracture,
.operations-greater-tuberosity-fracture,
.operations-proximal-humerus-fracture,
.operations-humeral-shaft-fracture,
.operations-glenoid-fracture,
.operations-fusion,
.operations-prostalac {
  .accordion {
    .accordion-item{
      border: 2px solid rgb(85, 171, 103);
      border-left: 10px solid rgb(85, 171, 103);
      .catalog-entry-header {
        width: 100%;
        .accordion-header {
          margin: 0;
        }
      }
      .catalog-entry-preview {
        padding: 0 20px;
        font-size: 0.8em;
        font-weight: lighter;
        margin: 0 0 10px 0;
        span {
          white-space:nowrap;
        }
        .catalog-entry-preview-key {
          font-weight: bold;
        }
      }
      .accordion-collapse, .collapse, .show {
        .accordion-body  {
          border-top: 1px solid rgb(85, 171, 103);
        }
      }
    }
  }
}