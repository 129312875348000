@import url('https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/2.8.0/react-datepicker.min.css');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.decorated {
  overflow: hidden;
  text-align: center;
}
.decorated > span{
    position: relative;
    display: inline-block;
}
.decorated > span:before, .decorated > span:after {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 2px solid;
    width: 55%;
    margin: 0 20px;
    color: lightgray;
}
.decorated > span:before{
    right: 100%;
}
.decorated > span:after{
    left: 100%;
}

/* Plain CSS */