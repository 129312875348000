.edit-survey {
  width: 100%;
  max-width: 800px;
  margin-bottom: 30px;

  .loader {
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h2 {
    margin: 0;
  }
  .edit-survey-header {
    width: 100%;
    padding: 20px 0 10px 0;
    margin: 0 0 30px 0;
    .survey-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin: 0 0 10px 0;   
    }
    .custom-patient-id {
      width: 100%;
      display: flex;
      p {
        margin: 0 10px 0 0;
      }
    }
  }
  .survey-details {
    width: 100%;
    .survey-details-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 10px 0;
      h4 {
        width: 33%;
        margin: 0;
      }
    }
    .survey-buttons {
      width: 66%;
      display: flex;
      justify-content: flex-end;
      button {
        margin: 0 0 0 10px;
      }
    }
    .date-of-submission {
      width: 100%;
    }
    .accordion {
      margin-bottom: 10px;
      .accordion-item {
        border: 2px solid rgba(33,37,41, 0.8);
        border-left: 10px solid rgba(33,37,41, 0.8);
        .accordion-header {
          width: 100%;
          .accordion-button:not(.collapsed) {
            color: black; 
          }
        }
        .accordion-collapse, .collapse, .show {
          .accordion-body  {
            border-top: 1px solid rgba(33,37,41, 0.8);
          }
        }
        .accordion-body {
          .self-assessment-test{
            max-width: 720px;
            .form-title {
              font-size: 14pt;
              font-weight: 700;
            }
            .form-subtitle {
              text-align: justify;
            }
            .form-group {
              margin-bottom: 20px;
              margin-top: 10px;
            }
            .form-label {
              display: block;
              text-align: justify;
            }
            .form-text {
              display: block;
            }
            .pain-slider {
              width:100% ;
              display: flex;
              flex-direction: row;
              .form-range {
                height: 40px;
                margin-right: 10px;
                accent-color: darkgray; 
                --progress-background: darkgray;           
              } 
              .form-control {
                width: 120px;
                height: 40px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .edit-survey {
    .survey-details {
      width: 100%;
      .survey-details-header {
        flex-direction: column;
        align-items: flex-start;
        h4 {
          width: 100%;
          margin: 0 0 10px 0;
        }
      }
      .survey-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .dropdown {
          margin: 0 10px 0 0;
        }
        button {
          margin: 0 0 0 10px;
          &:last-of-type {
            margin: 0 0 0 0;
          }
        }
      }
    }
  }
}