.dashboard {
  width: 100%;
  max-width: 1300px;
  .loader {
    height: calc(100vh - 56px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dashboard-header {
    width: 100%;
    padding: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .time-interval {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .form-label {
        text-align: right;
        margin: 0 10px 0 0;
      }
      .form-select {
        max-width: 150px;
      }
    }
    .compliance-scores {
      display: flex;
      flex-direction: row;
      .progress-bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        p {
          margin: 0 0 0 0;
        }
        margin-right: 20px;
      }
    }
  }

  .dashboard-table {
    width: 100%;
    overflow-x:scroll;
    .table {
      // text-align: center;
      width: 100%;
      min-width: 900px;
      table-layout: fixed;
      td {
        overflow:hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        vertical-align: middle;
      }
      .when {
        width: 16%;
      }
      .deadline {
        width: 12.5%
      }
      .custom-id {
        width: 17.5%;
      }
      .study {
        width: 9%;
      }
      .event-date {
        width: 15%
      }
      .milestone { 
        width: 15%;
      }
      .status {
        width: 5%;
        text-align: center;
        text-overflow: none;
      }
      .button {
        width: 10%;
        text-align: right;
      }
      .red-dot {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 25px;
        width: 25px;
        background-color: rgb(255, 51, 51);
        border-radius: 50%;
        display: inline-block;
      }
      .purple-dot {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 25px;
        width: 25px;
        background-color: rgb(75,0,130);
        border-radius: 50%;
        display: inline-block;
      }
      .green-dot {
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        width: 25px;
        background-color: rgb(51, 255, 51)en;
        border-radius: 50%;
        display: inline-block;
      }
      .yellow-dot {
        color: black;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        width: 25px;
        background-color: rgb(249, 242, 47);
        border-radius: 50%;
        display: inline-block;
      }
    }
  }
  .status-tooltip { 
    box-shadow: 0 0 10px rgba(0,0,0,0.23);
    padding: 0px;
    border-radius: 15px;
    opacity: 1;
    border: 1px solid #c4c4c4;
    background-color: white;
    color: black;
    height: 350px;
    width: 350px;
    .tooltip-body {
      width: 100%;
      height: 100%;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .open-survey {
        width: 100%;
        button {
          width: 50%;
          margin: 10px 25%;
        }
      }
      .divider {
        // clear:both;
        // display:block;
        width: 80%;               
        background-color:#FFFF00;
        height: 1px;
      }
      .survey-details {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .reminder-details {
          margin: 10px 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          button {
            width: 50%;
          }
        }
        .patient-details {
          width: 100%;
          flex-direction: column;
          display: flex;
          align-items: center;
          margin: 10px 0;
        }
        p {
          margin-bottom: 0px;
          font-size: 12pt;
        }
      }
    }
  }
  .react-tooltip-arrow {
    left: 334px !important;
    // bottom: -15px !important;
    width: 30px;
    height: 30px;
    background-color: white;
    // border-bottom: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    // border-radius: 10px;
    // border-left: 1px solid #c4c4c4;
    border-top: 1px solid #c4c4c4;
  }
}

@media (max-width: 621px) {
  .dashboard {
    .dashboard-header {
      flex-direction: column;
      .time-interval {
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        .form-label {
          text-align: left;
        }
      }
    }
  }
}