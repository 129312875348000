.operations-l-and-i-slap,
.operations-l-and-i-labral-repair,
.operations-l-and-i-capsulorrhaphy,
.operations-l-and-i-hagl-repair,
.operations-l-and-i-alpsa-repair,
.operations-l-and-i-remplissage,
.operations-l-and-i-subscapularis-augmentation,
.operations-l-and-i-glenoid-bone-graft-procedure,
.operations-l-and-i-humeral-bone-graft-procedure,
.operations-l-and-i-dynamic-anterior-stabilization {
  .accordion {
    .accordion-item{
      border: 2px solid rgb(85, 171, 103);
      border-left: 10px solid rgb(85, 171, 103);
      .catalog-entry-header {
        width: 100%;
        .accordion-header {
          margin: 0;
        }
      }
      .catalog-entry-preview {
        padding: 0 20px;
        font-size: 0.8em;
        font-weight: lighter;
        margin: 0 0 10px 0;
        span {
          white-space:nowrap;
        }
        .catalog-entry-preview-key {
          font-weight: bold;
        }
      }
      .accordion-collapse, .collapse, .show {
        .accordion-body  {
          border-top: 1px solid rgb(85, 171, 103);
        }
      }
    }
  }
}