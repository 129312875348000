.notifications {
  width: 100%;
  max-width: 1300px;
  h2, h4 {
    margin: 0;
  }
  .loader {
    height: calc(100vh - 56px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notifications-header {
    width: 100%;
    padding: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .notifications-table {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 20px;
    table {
      width: 100%;
      min-width: 900px;
      table-layout: fixed;
      td {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        vertical-align: middle;
      }
      .alert-date {
        width: 15%;
      }
      .custom-id {
        width: 20%;
      }
      .study {
        width: 10%;
      }
      .alert-details {
        width: 15%
      }
      .milestone { 
        width: 15%;
      }
      .actions {
        width: 12.5%;
      }
      .notification-action-buttons {
        width: 12.5%;
      }
      td.notification-action-buttons {
        text-overflow: clip;
        div {
          width: 100%;
          display: flex;
          justify-content: space-around;
        }
      }
      .terms-and-condition-action-buttons {
        width: 17.5%;
      }
      td.terms-and-condition-action-buttons {
        text-overflow: clip;
        div {
          width: 100%;
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
}