.pre-treatment {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  Form {
    width: 100%;
    max-width: 800px;
    .card {
      width: 100%;
    }
    .card-header {
      font-size: 14pt;
      font-weight: 700;
    }
    .card-title {
      margin-top: 20px;
      margin-bottom: 36px;
    }
    .card-footer {
      height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .form-content {
      .self-assessment-test{
        max-width: 720px;
        .form-title {
          font-size: 14pt;
          font-weight: 700;
        }
        .form-subtitle {
          text-align: justify;
        }
        .form-group {
          margin-bottom: 20px;
          margin-top: 10px;
        }
        .form-label {
          display: block;
          text-align: justify;
        }
        .form-text {
          display: block;
        }
        .pain-slider {
          width:100% ;
          display: flex;
          flex-direction: row;
          .form-range {
            height: 40px;
            margin-right: 10px;
            accent-color: pink; 
            --progress-background: pink;           
          } 
          .form-control {
            width: 120px;
            height: 40px;
            text-align: center;
          }
        }
      }
    }
  }
}