.patients {
  width: 100%;
  max-width: 1300px;
  .loader {
    height: calc(100vh - 56px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .patients-header {
    width: 100%;
    padding: 20px 0 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    z-index: -1;
  }
  .filters {
    padding: 10px 0 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    z-index: -1;
    .search {
      // width: 50%;
      width: calc(100% - 215px);
      margin-right: 10px;
    }
  }
  .patients-table {
    width: 100%;
    overflow-x: scroll;
    table {
      width: 100%;
      min-width: 850px;
      table-layout: fixed;
      th {
        .show-hide-anchor {
          color: blue;
          font-weight: 400;
          text-decoration: underline;
          &:hover {
            cursor: pointer;
          }
        }
      }
      td {
        overflow:hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        vertical-align: middle;
      }
      .custom-id {
        width: 20%;
      }
      .email {
        width: 30%;
      }
      .study {
        width: 10%;
      }
      .event-date {
        width: 15%
      }
      .ps .ss {
        width: 7,5%;
        text-overflow: none;
      }
      .button {
        width: 10%;
        text-align: right;
      }
    }
  }
  .sort{
    width: 100%;
    padding: 10px 0;
  }
  .react-datepicker__aria-live {
    display: none;
  }
  .procedure-date-picker {
    width: 215px;
  }
  .dp-footer {
    width: 100%;
    display: flex;
    padding: 10px;
    justify-content: space-around;
  }
}
