.complication-rotator-cuff, 
.complication-labrum-and-instability, 
.complication-ac-joint, 
.complication-cartilage-osteochondral, 
.complication-bleeding-hematologic-clotting, 
.complication-nervous-system, 
.complication-infection, 
.complication-excessive-swelling-sepsis-wound, 
.complication-motion-loss, 
.complication-fracture-and-dislocation, 
.complication-heterotopic-ossification, 
.complication-non-union-pseudoarthrosis-malunion, 
.complication-avn, 
.complication-motion-loss, 
.complication-general-medical,
.complication-arthroplasty-failure,
.complication-graft-failure,
.complication-tendon-repair-or-reconstruction,
.complication-instability {
  .accordion-item{
    border: 2px solid rgb(57, 72, 92);
    border-left: 10px solid rgb(57, 72, 92);
    .catalog-entry-header {
      width: 100%;
      .accordion-header {
        margin: 0;
      }
    }
    .catalog-entry-preview {
      padding: 0 20px;
      font-size: 0.8em;
      font-weight: lighter;
      margin: 0 0 10px 0;
      span {
        white-space:nowrap;;
      }
      .catalog-entry-preview-key {
        font-weight: bold;
      }
    }
    .accordion-collapse, .collapse, .show {
      .accordion-body  {
        border-top: 1px solid rgb(57, 72, 92);
      }
    }
  }
} 